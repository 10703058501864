'use client'

import AudioContext from '@/contexts/AudioContext'
import LocationAwareQueuer from './LocationAwareQueuer'

export default function AudioContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <AudioContext.Provider>
      <LocationAwareQueuer />
      {children}
    </AudioContext.Provider>
  )
}
