'use client'

import { usePathname } from 'next/navigation'
import { useContext, useEffect } from 'react'
import Audio from '@/contexts/AudioContext'
import Wallet from '@/contexts/WalletContext'
import { logEvent } from '@/lib/utils/event'

export default function LocationAwareQueuer() {
  const pathname = usePathname()

  const {
    queue,
    fetchInitialQueueFromNinasPicks,
    fetchInitialQueueFromHub,
    fetchInitialQueueFromProfile,
    fetchInitialQueueForPost,
    fetchInitialQueueForRelease,
  } = useContext(Audio.Context)

  const { wallet, profile } = useContext(Wallet.Context)
  useEffect(() => {
    let action: ((publicKey: string) => void) | null = null
    let publicKey: string | undefined = undefined

    if (pathname.includes('/articles/') && pathname !== '/articles/create') {
      publicKey = pathname.split('/articles/')[1]
      action = fetchInitialQueueForPost
      logEvent('article_viewed', 'view', wallet, { publicKey })
    } else if (pathname.includes('/hubs/') && !pathname.includes('/embed')) {
      publicKey = pathname.split('/hubs/')[1]
      action = fetchInitialQueueFromHub
      logEvent('hub_viewed', 'view', wallet, { publicKey })
    } else if (pathname.includes('/embed/hubs/')) {
      logEvent('hub_embed_viewed', 'view', wallet)
    } else if (
      pathname.includes('/releases/') &&
      pathname !== '/releases/uploading'
    ) {
      publicKey = pathname.split('/releases/')[1]
      action = fetchInitialQueueForRelease
      logEvent('release_viewed', 'view', wallet, { publicKey })
    } else if (pathname.includes('/profiles/')) {
      publicKey = pathname.split('/profiles/')[1]
      action = fetchInitialQueueFromProfile
      logEvent('release_viewed', 'view', wallet, { publicKey })
    } else {
      if (profile && pathname.includes(profile.handle)) {
        logEvent('dashboard_viewed', 'view', wallet)
      }

      action = fetchInitialQueueFromNinasPicks
    }

    if (queue.length === 0 && action) {
      if (publicKey) {
        action(publicKey)
      } else {
        action('')
      }
    }
  }, [pathname])

  return <></>
}
